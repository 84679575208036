document.addEventListener("DOMContentLoaded", function(event) {});

var clientId = 'wonNdA5JRoxGk0g48qWceZunaT4fFSaFC35C6a+Gi206y8aR6y2I2fOM6ChJuQD9oTJg5HdGODz3Bn7mHTzpmp+yFqSl04jWjzCPHFHpizs=';
//var domainName = 'https://farmersmarketcoalition.z2systems.com/np';
var domainName = fmcGlobalVars.nedonDomain;

function fn_go2link(link){
	window.document.location.href= link;
}

var endpoint =domainName + "/oauth/auth?";

var param = "response_type=code&client_id=" + encodeURIComponent(clientId) + "&";


for(var prop in setting){
	if(setting.hasOwnProperty(prop)){
		param = param + prop + "=" + encodeURIComponent(setting[prop]) + "&";
	}
}
endpoint = endpoint + param;


//default css
var n_css = {
	"display" : "inline-block",
	"width" : "100%",
	"height" : "32px",
	//"margin-bottom" : "20px",
	"line-height" : "32px",
	"font-family" : "Arial",
	"font-size" : "15px",
	"font-weight" : "normal",
	"color" : "#fff",
	"letter-spacing" : "0px",
	"box-shadow" : "inset 0 1px #69C4EA",
	"background-color" : "#00ACED",
	"border" : "solid 1px #239CCF",
	"border-radius" : "4px",
	"text-align" : "center",
	"vertical-align" :  "middle",
	"cursor" : "pointer",
	"label" : "Log In"
}

//custom css overwrite NEON default css
if(typeof(c_css)!=undefined){
	try{
		for(var prop in c_css){
			if(c_css.hasOwnProperty(prop)){
				n_css[prop] = c_css[prop];
			}
		}
	}catch(e){
		console.log("Error occurred when overwrite the default css.");
		console.log(e);
	}
}

var style = "";
for(var prop in n_css){
	if(n_css.hasOwnProperty(prop)){
		//console.log(n_css[prop]);
		if(prop != "label")
			style += prop + ":" + n_css[prop] + ";";
	}
}
style = style.replace(/'/g, '');

html = "<div id='neonCrmLoginButtonWrapper' style='" + style + "' onclick='fn_go2link(\"" + endpoint + "\")'>" + n_css["label"] + "</div>";
var scriptTag = document.getElementsByTagName('script');
scriptTag = scriptTag[scriptTag.length - 1];

//var parentTag = scriptTag.parentNode;
var parentTag = document.getElementById('neonLoginButtonHolder');
//var elem = document.createElement("div");
// elem.innerHTML = html;
// parentTag.insertBefore(elem,scriptTag);

parentTag.innerHTML = html;